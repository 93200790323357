import { useEffect, useState } from "react";
import pinApi from "api/pin";
import objectToQueryString from "common/utils/object-to-query-string";
import { useUserState } from "../../user/context/user-provider";

export default function ({ params } = {}) {
  const { state: { isBartender } } = useUserState();
  const [state, setState] = useState({
    pins: [],
    isFetched: false,
    loading: false,
    error: null,
    searchedPins: [],
  });

  const fetch = () => {
    setState(prevState => ({ ...prevState, loading: true }));
    const api = isBartender ? "getPinUserList" :"getPinAdminList"
    pinApi
      [api]({ params: objectToQueryString(params) })
      .then(response => {
        const pins = isBartender ? response.data.map(data=>data.pin): (response.data || []);
        setState(prevState => ({
          ...prevState,
          pins: objectToQueryString(params) ? state.pins : pins,
          searchedPins: pins,
          isFetched: true,
          loading: false,
        }));
      })
      .catch(error => {
        setState(prevState => ({
          ...prevState,
          error: error?.message ?? true,
          loading: false,
        }));
      });
  };

  useEffect(() => {
    fetch();
  }, [objectToQueryString(params), isBartender]);

  return state;
}
