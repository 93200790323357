import './auth-layout.less'
import $t from 'translation'
import { Outlet } from 'react-router-dom';
import Logo from 'assets/images/logo.png'

export default function () {
  return(
    <div className='auth-layout'>
      <div className={'auth-layout__content'}>
        <img
          src={Logo}
          alt={'logo'} title={'logo'}
          className={'auth-layout__content-image'}
        />

        <h2 className={'auth-layout__content-header'}>{ $t('app.title') }</h2>

        <div className={'auth-layout__form'}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
