import { ROLES } from "./roles";

export const dashboardMenuItems = [
  {
    key: "analytics",
    url: "/analytics",
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    key: "tickets",
    url: "/tickets",
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    key: "menu",
    url: "/products",
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    key: "orders",
    url: "/orders",
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
];

export const leftPanelMenuItems = [
  {
    key: "dashboard",
    url: "/pin/:pinId/analytics",
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    key: "info",
    url: "/pin/:pinId/info",
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    key: "finance",
    url: "/pin/:pinId/revenue",
    roles: [ROLES.BARTENDER, ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
];

export const pinInfoMenuItems = [
  {
    key: "hours",
    url: "/hours",
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    key: "design",
    url: "/design",
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
];

export const financeInfoMenuItems = [
  {
    key: "revenue",
    url: "/revenue",
    roles: [ROLES.BARTENDER, ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    key: "tips",
    url: "/tips",
    roles: [ROLES.BARTENDER, ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
  {
    key: "bank",
    url: "/bank",
    roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN],
  },
];

export const superAdminMenuItems = [
  {
    key: "analytics.total",
    url: "/admin/analytics/total",
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    key: "analytics.venue",
    url: "/admin/analytics/venue",
    roles: [ROLES.SUPER_ADMIN],
  },
  {
    key: "analytics.users",
    url: "/admin/users",
    roles: [ROLES.SUPER_ADMIN],
  },
];