import React, {useCallback} from "react";
import clsx from "clsx";
import './city.less'

export default ({ city, active, selectCity}) => {

  const handleClick = useCallback(() => {
    selectCity(city)
  }, [])

  return(
    <div
      className={clsx("city-item", {
        "city-item--active": active
      })}
      onClick={handleClick}
    >
      {city.name}
    </div>
  )
}