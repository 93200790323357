import cityApi from "api/city";
import { useEffect, useState } from "react";
import objectToQueryString from "common/utils/object-to-query-string";

export default function ({ params } = {}) {
  const [state, setState] = useState({
    cities: [],
    isFetch: false,
  });

  useEffect(() => {
    setState({
      ...state,
      cities: [],
      isFetch: false,
    });
    cityApi
      .getCityList({ params: objectToQueryString(params) })
      .then(response => {
        setState({
          cities: response.data ?? [],
          isFetch: true,
        });
      })
      .catch(error => {
        console.log(error?.message);
      });
  }, [objectToQueryString(params)]);

  return state;
}
