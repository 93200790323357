import { UserProvider } from "features/user/context/user-provider";
import React from "react";
import MainRouter from "router";

function App() {
  return (
    <UserProvider>
      <MainRouter />
    </UserProvider>
  );
}

export default App;
