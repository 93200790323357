import React, { Suspense } from "react";
import MainLayout from "components/main-layout";
import AuthLayout from "components/auth/layout";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import LoadingComponent from "components/loading";
import ProtectedRoute from "../components/protected-route";
import { ROLES } from "../common/constants/roles";
import NotAuthorized from "../pages/not-authorized";

const LoginPage = React.lazy(() => import("pages/auth/login"));
const ChangePasswordPage = React.lazy(() => import("pages/auth/change-password"));
const ForgotPasswordPage = React.lazy(() => import("pages/auth/forgot-password"));
const TicketsPage = React.lazy(() => import("pages/ticket/list"));
const TicketForm = React.lazy(() => import("pages/ticket/form"));
const AnalyticsPage = React.lazy(() => import("pages/analytics"));
const ProductsPage = React.lazy(() => import("pages/products/main"));
const ProductSettingForm = React.lazy(() => import("pages/products/form/setting"));
const TipForm = React.lazy(() => import("pages/products/form/tip"));
const CategoryForm = React.lazy(() => import("pages/products/form/category"));
const ProductForm = React.lazy(() => import("pages/products/form/product"));
const OrdersPage = React.lazy(() => import("pages/orders"));
const PinDetailsPage = React.lazy(() => import("pages/pin/details"));
const PinHoursPage = React.lazy(() => import("pages/pin/hours"));
const PinDealsPage = React.lazy(() => import("pages/pin/deals"));
const PinDesignPage = React.lazy(() => import("pages/pin/design"));
const FinanceRevenuePage = React.lazy(() => import("pages/finance/revenue"));
const FinanceTipsPage = React.lazy(() => import("pages/finance/tips"));
const FinanceBankPage = React.lazy(() => import("pages/finance/bank"));
const PaymentPage = React.lazy(() => import("pages/payment"));
const AdminAnalyticsTotalPage = React.lazy(() => import("pages/admin/analytics/total"));
const AdminAnalyticsVenuePage = React.lazy(() => import("pages/admin/analytics/venue"));
const AdminUsersPage = React.lazy(() => import("pages/admin/users"));
const AdminUserForm = React.lazy(() => import("pages/admin/users/create"));

const loader = async () => {
  const user = localStorage.getItem("access_token");
  const isFirstTimeLogin = localStorage.getItem("isFirstTimeLogin");
  const isPasswordChanged = localStorage.getItem("isPasswordChanged");

  if (!user) {
    return redirect("/auth/login");
  } else if (isFirstTimeLogin && !isPasswordChanged) {
    return redirect("/auth/change-password");
  }
};

const redirectIfUser = async () => {
  const user = localStorage.getItem("access_token");
  if (user) {
    return redirect("/");
  }
};

const redirectIfUserChangedPassword = async () => {
  const isPasswordChanged = localStorage.getItem("isPasswordChanged");
  if (isPasswordChanged) {
    return redirect("/");
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    loader,
    children: [
      {
        element: <ProtectedRoute allowedRoles={[ROLES.BARTENDER, ROLES.ADMIN, ROLES.SUPER_ADMIN]} />,
        children: [
          { loader, path: "pin/:pinId/revenue", element: <FinanceRevenuePage /> },
          { loader, path: "pin/:pinId/tips", element: <FinanceTipsPage /> },
        ],
      },
      {
        element: <ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]} />,
        children: [
          { loader, path: "pin/:pinId/analytics", element: <AnalyticsPage /> },
          { loader, path: "pin/:pinId/tickets", element: <TicketsPage /> },
          { loader, path: "pin/:pinId/products", element: <ProductsPage /> },
          { loader, path: "pin/:pinId/products/setting", element: <ProductSettingForm /> },
          { loader, path: "pin/:pinId/products/setting/tips/create", element: <TipForm /> },
          { loader, path: "pin/:pinId/products/setting/tips/:tipId", element: <TipForm /> },
          { loader, path: "pin/:pinId/products/categories/create", element: <CategoryForm /> },
          { loader, path: "pin/:pinId/products/categories/:categoryId", element: <CategoryForm /> },
          { loader, path: "pin/:pinId/products/create", element: <ProductForm /> },
          { loader, path: "pin/:pinId/products/:productId", element: <ProductForm /> },
          { loader, path: "pin/:pinId/orders", element: <OrdersPage /> },
          { loader, path: "pin/:pinId/info", element: <PinDetailsPage /> },
          { loader, path: "pin/create", element: <PinDetailsPage /> },
          { loader, path: "pin/:pinId/hours", element: <PinHoursPage /> },
          { loader, path: "pin/:pinId/deals", element: <PinDealsPage /> },
          { loader, path: "pin/:pinId/design", element: <PinDesignPage /> },
          { loader, path: "pin/:pinId/tickets/create", element: <TicketForm /> },
          { loader, path: "pin/:pinId/tickets/:ticketId", element: <TicketForm /> },
          { loader, path: "pin/:pinId/bank", element: <FinanceBankPage /> },
          { loader, path: "pin/:pinId/payment", element: <PaymentPage /> },
        ],
      },
      {
        element: <ProtectedRoute allowedRoles={[ROLES.SUPER_ADMIN]} />,
        children: [
          { loader, path: "admin/analytics/total", element: <AdminAnalyticsTotalPage /> },
          { loader, path: "admin/analytics/venue", element: <AdminAnalyticsVenuePage /> },
          { loader, path: "admin/users", element: <AdminUsersPage /> },
          { loader, path: "admin/users/create", element: <AdminUserForm /> },
        ],
      },
      {
        path: "/pin/:pinId/not-authorized",
        element: <NotAuthorized />,
        loader,
      },
    ],
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/auth/login",
        element: <LoginPage />,
        loader: redirectIfUser,
      },
      {
        path: "/auth/change-password",
        element: <ChangePasswordPage />,
        loader: redirectIfUserChangedPassword,
      },
      {
        path: "/auth/forgot-password",
        element: <ForgotPasswordPage />,
        loader: redirectIfUser,
      },
    ],
  },
]);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default () => (
  <Suspense fallback={LoadingComponent}>
    <RouterProvider router={router} />
  </Suspense>
);
