import userActions from "features/user/action";
import { ROLES } from "../../../common/constants/roles";

export const userInitState = {
  isBartender: false,
  isAdmin: false,
  isSuperAdmin: false,
};

export const UserReducer = (state = userInitState, action) => {
  switch (action.type) {
    case userActions.FETCH: {
      const { userRole } = action.payload;
      localStorage.setItem("role", userRole);

      return {
        ...state,
        isBartender: userRole === ROLES.BARTENDER,
        isAdmin: userRole === ROLES.ADMIN,
        isSuperAdmin: userRole === ROLES.SUPER_ADMIN,
        role: userRole
      };
    }

    case userActions.CHANGE_PASSWORD: {
      localStorage.setItem("isFirstTimeLogin", true);
      localStorage.setItem("access_token", action.payload.access);
      localStorage.setItem("refresh_token", action.payload.refresh);
      localStorage.setItem("oldPassword", action.payload.password);
      return {
        ...state,
      };
    }

    case userActions.LOGIN: {
      if (action.payload) {
        localStorage.setItem("access_token", action.payload.access);
        localStorage.setItem("refresh_token", action.payload.refresh);
      }
      window.location.reload();
      return {
        ...state,
      };
    }

    case userActions.LOGOUT: {
      localStorage.clear()
      window.location.reload();

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
