import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { ROLES } from "../../common/constants/roles";

const ProtectedRoute = ({ allowedRoles }) => {
  const  role =localStorage.getItem("role");
  const { pinId } = useParams();
  const user = localStorage.getItem("access_token");

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(role) && role === ROLES.BARTENDER) {
    return <Navigate to={`/pin/${pinId}/revenue`} />;
  }

  if (allowedRoles && !allowedRoles.includes(role)) {
    return <Navigate to={`/pin/${pinId}/not-authorized`} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;