import {createContext, useContext, useEffect, useReducer} from "react";
import { UserReducer, userInitState } from "features/user/reducer";
import userApi from 'api/user';
import userActions from "../action";

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, userInitState);

  useEffect(() => {
    const userToken = localStorage.getItem("access_token");
    if (userToken) {
      userApi.getCurrentUser()
        .then(({ data }) => {
          dispatch({ type: userActions.FETCH, payload: data });
        })
        .catch(error => {
          dispatch({ type: userActions.LOGOUT });
        });
    }
  }, []);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

const useUserState = () => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return userContext;
};

export { UserProvider, useUserState };
