import React, {useEffect, useState} from "react";
import {Layout as AntLayout} from "antd";
import Drawer from "components/drawer";
import {Link, Outlet, useParams} from "react-router-dom";
import Logo from "assets/images/logo.png";
import Icon from "components/icon";
import {PinsProvider} from "features/pin/context/pins-provider";
import $t from "translation";
import moment from "moment";
import "./layout.less";
import {momentFormat} from "common/constants/moment-format";
import financeApi from 'api/finance';

const {Header, Content} = AntLayout;

const Layout = ({checkSelectedPin}) => {
  const [isDrawerOpen, setOpen] = useState(false);
  const [totalWeeksRevenue, setTotalWeeksRevenue] = useState(0);
  const {pinId} = useParams();

  useEffect(() => {
    if (pinId) {
      financeApi
        .getWeekRevenue({
          pinId,
          params: {
            fromDate: moment().startOf("week").utc().format(momentFormat),
          },
        })
        .then(response => {
          setTotalWeeksRevenue(response?.data || 0);
        })
        .catch(error => console.log(error?.message));
    }
  }, [pinId]);

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <PinsProvider {...{checkSelectedPin}}>
      <AntLayout className={"main-layout"}>
        <Drawer closeDrawer={closeDrawer} open={isDrawerOpen}/>
        <AntLayout>
          <Header className="main-layout__header">
            <Icon type="hamburger" onClick={openDrawer}/>
            <div className="header__text-wrapper header__date">
              <span>{$t("today.date")}:</span>
              <span>{moment().format("dddd MMM Do, YYYY")}</span>
            </div>
            <div className="header__text-wrapper header__total-revenue">
              <span>{$t("this.week.revenue")}:</span>
              <span>{`$${totalWeeksRevenue}`}</span>
            </div>
            <Link to={`/pin/${pinId}/analytics`} className="main-layout__logo">
              <img width={40} src={Logo} alt="logo"/>
            </Link>
          </Header>
          <Content className="main-layout__content">
            <Outlet/>
          </Content>
        </AntLayout>
      </AntLayout>
    </PinsProvider>
  );
};

export default Layout;
