import React, {useRef} from "react";
import {Empty, Spin, Tooltip} from "antd";
import Icon from "components/icon";
import {Link, useParams} from "react-router-dom";
import $t from "translation";
import NotFoundImage from "assets/images/not-found-image.svg";
import clsx from "clsx";
import {useDrag, useDrop} from 'react-dnd';
import "./list.less";
import {useUserState} from 'features/user/context/user-provider';

export default function ({hasAccess, pins, loading, isFetched, movePin}) {
  const {pinId} = useParams();
  const { state } = useUserState();
  const { isSuperAdmin } = state;

  const RenderPin = ({pin, position}) => {
    const ref = useRef(null)

    const [{isOver}, drop] = useDrop({
      accept: "pin",
      hover(pin, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = pin.position
        const hoverIndex = position
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the right
        const hoverClientX = clientOffset.x - hoverBoundingRect.right
        if (dragIndex < hoverIndex && hoverClientX > hoverMiddleX) {
          return
        }
        // Dragging left
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return
        }
        // Time to actually perform the action
        movePin(dragIndex, hoverIndex, isOver)
        pin.position = hoverIndex
      },
      drop: (pin, monitor) => movePin(pin.position, position, isOver),
      collect: monitor => ({
        isOver: !!monitor.isOver()
      })
    });

    const [{isDragging}, drag] = useDrag({
      canDrag: isSuperAdmin,
      type: "pin",
      item: {position, pin, id: pin.id},
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
      <div className="pin-item" key={pin.id} ref={ref} style={{opacity}}>
        <Link
          to={`/pin/${pin.id}/analytics`}
          key={pin.id}
          className={clsx("pin-item__title", {
            "pin-item__title-active": pin.id === pinId
          })}
        >
          <img
            src={pin.image?.fileUrl || NotFoundImage}
            className="pin-item__avatar"
          />
          <span className="pin-item__text">{pin?.name}</span>
        </Link>

        {hasAccess && <Tooltip title={$t("edit")}>
          <Link className={"pin-item__menu--link"} to={`/pin/${pin.id}/info`}>
            <Icon type="edit" width={15}/>
          </Link>
        </Tooltip>
        }

        {hasAccess && isSuperAdmin && <Icon type="equal" width={20}/>}
      </div>
    )
  }

  if (loading) {
    return <Spin/>;
  } else if (isFetched && pins.length === 0) {
    return (
      <Empty
        description={$t("errors.no.field", {field: $t("pins")})}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    );
  } else {
    return pins.map((pin, position) => (
      <RenderPin key={pin.id} pin={pin} position={position}/>
    ));
  }
}
