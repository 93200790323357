import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import usePinsData from "features/pin/hooks/usePinsData";
import pin from "../../../api/pin";

export const PinContext = createContext();

const PinsProvider = ({ children }) => {
  const [state, setState] = useState({
    pins: [],
    isFetched: false,
    selectedPin: null,
    params: {},
  });
  const pinsState = usePinsData({ params: state.params });
  const { pinId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pinsState.isFetched) {
      setState(prevState => ({
        ...prevState,
        pins: pinsState.pins,
        isFetched: pinsState.isFetched,
      }));
    }
  }, [pinsState]);

  useEffect(() => {
    if (state.isFetched) {
      let pin;
      if (pinId) {
        pin = state.pins.find(pin => pin.id === pinId);
      }
      setState(prevState => ({
        ...prevState,
        selectedPin: pin ?? state.pins[0],
      }));
    }
  }, [pinId, state.isFetched, state.pins]);

  // Only when url is '/' we need to navigate to pin page
  const mustNavigateToPinPage = () => {
    return location.pathname === "/" ? true : false;
  };

  const navigateToPingPage = (pinId) => {
    const url = pinId ? `/pin/${pinId}/analytics` : '/';
    navigate(url, { replace: true });
  }

  useEffect(() => {
    if (mustNavigateToPinPage() && state.selectedPin) {
      navigateToPingPage(state.selectedPin.id)
    }
  }, [state.selectedPin]);

  const handleSearchPin = newParams => {
    setState(prevState => ({
      ...prevState,
      params: newParams,
    }));
  };

  const handleAddPin = newPin => {
    setState(prevState => ({
      ...prevState,
      pins: [...state.pins, newPin],
      searchedPins: [...state.pins, newPin],
      params: {},
    }));
  };

  const handleEditPin = editedPin => {
    const pins = state.pins;
    const index = pins.findIndex(item => item.id === editedPin.id);
    pins[index] = editedPin;

    setState(prevState => ({
      ...prevState,
      pins,
      selectedPin: editedPin,
    }));

  };

  const handleDeletePin = deletedPinId => {
    const pins = state.pins.filter(pin =>pin.id !== deletedPinId);
    const selectedPin = pins[0];
    setState(prevState => ({
      ...prevState,
      pins,
      selectedPin
    }));

    navigateToPingPage(selectedPin.id)
  };

  return (
    <PinContext.Provider
      value={{
        ...pinsState,
        ...state,
        handleAddPin,
        handleEditPin,
        handleSearchPin,
        handleDeletePin
      }}
    >
      {children}
    </PinContext.Provider>
  );
};

const usePinContext = () => {
  const pinContext = useContext(PinContext);
  if (!pinContext) {
    throw new Error("usePin must be used within a PinProvider");
  }
  return pinContext;
};

export { PinsProvider, usePinContext };
