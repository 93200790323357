import Client from "./client";

const baseUrl = `/user`;

export default {
  login: ({ payload }) => {
    const url = baseUrl + "/admin/login";
    return Client.post(url, payload);
  },
  changePassword: ({ payload }) => {
    const url = baseUrl + "/change-password";
    return Client.post(url, payload);
  },
  forgotPassword: ({ payload }) => {
    const url = baseUrl + "/forgot-password";
    return Client.post(url, payload);
  },
  forgotPasswordGetCode: ({ payload }) => {
    const url = baseUrl + "/forgot-password/get-code";
    return Client.post(url, payload);
  },
  getCurrentUser: () => {
    const url = baseUrl + "/me";
    return Client.get(url);
  },
  getAdminUsersList: ({ params }) => {
    return Client.get(baseUrl, { params });
  },
  createAdminUser: ({ payload }) => {
    return Client.post(baseUrl + '/admin', payload);
  },
  getAdminUserPins: ({ params }) => {
    return Client.get('/pin-owner', { params });
  },
  getUserPins: ({ params }) => {
    return Client.get(`/pin-staff/user/${params.user__id}`);
  },
  getAdminUsersOfPin: ({ params }) => {
    return Client.get(`/pin-owner/pin/${params.pinId}/users`);
  },
  createOwnerOfPin: ({ payload }) => {
    return Client.post('/pin-owner', payload);
  },
  createUserOfPin: ({ payload }) => {
    return Client.post('/pin-staff', payload);
  },
  deleteAdminOfPin: ({ params }) => {
    const url = `pin-owner/delete/user/${params.userId}/pin/${params.pinId}`;
    return Client.delete(url);
  },
  deleteUserOfPin: ({ params }) => {
    const url = `pin-staff/delete/user/${params.userId}/pin/${params.pinId}`;
    return Client.delete(url);
  },
  deleteUsersOfPin: ({ params }) => {
    const url = `pin-staff/${params.pinId}`;
    return Client.delete(url);
  },
};
