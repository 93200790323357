import Client from "./client";

const baseUrl = `/finance/pin`;

export default {
  getRevenue: ({ params, pinId }) => {
    const url = baseUrl + `/${pinId}/revenue`;
    return Client.get(url, {
      params
    });
  },

  getTips: ({ params, pinId }) => {
    const url = baseUrl + `/${pinId}/tips`;
    return Client.get(url, {
      params
    });
  },

  getWeekRevenue: ({ params, pinId }) => {
    const url = baseUrl + `/${pinId}/this-week-total-revenue`;
    return Client.get(url, {
      params
    });
  },

};
