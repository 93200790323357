import Client from "./client";

const baseUrl = `/pin`;

export default {
  getPinAdminList: ({ params = "" } = {}) => {
    const url = baseUrl + "/admin-list" + params;
    return Client.get(url);
  },
  getPinUserList: ({ params = "" } = {}) => {
    const url = "/pin-staff/me" + params;
    return Client.get(url);
  },

  getPinById: ({ params }) => {
    const url = baseUrl + `/${params.id}`;
    return Client.get(url);
  },

  createPin: ({ payload }) => {
    const url = baseUrl;
    return Client.post(url, payload);
  },

  updatePin: ({ payload, params }) => {
    const url = baseUrl + `/${params.id}`;
    return Client.patch(url, payload);
  },

  updatePinPosition: ({ payload }) => {
    const url = baseUrl + '/change-position';
    return Client.post(url, payload);
  },

  deletePin: ({ params }) => {
    const url = baseUrl +  `/${params.id}`
    return Client.delete(url);
  },

  getPinTypesList: () => {
    const url = baseUrl + "/type";
    return Client.get(url);
  },
};
