import React, { useEffect, useState, useCallback } from "react";
import { Collapse, Input, Popover } from "antd";
import clsx from "clsx";
import Icon from "components/icon";
import { usePinContext } from "features/pin/context/pins-provider";
import useCities from "features/city/hooks/useCities";
import { Link } from "react-router-dom";
import $t from "translation";
import PinsList from "features/pin/components/list";
import objectToQueryString from "common/utils/object-to-query-string";
import City from 'features/city/components/city'
import "./list-wrapper.less";
import update from 'immutability-helper';

const { Panel } = Collapse;

const initialState = {
  city: null,
  search: "",
};

export default function ({ hasAccess, pinsList, setPinsList, closeDrawer }) {
  const [state, setState] = useState(initialState);
  const { cities } = useCities();
  const {
    loading,
    isFetched,
    params,
    selectedPin,
    handleSearchPin,
  } = usePinContext();
  const [openCityPopover, setOpenCityPopover] = useState(false);

  useEffect(() => {
    handleSearchPin({ search: state.search, city__id: state.city?.id });
  }, [state]);

  //remove search query when user add new pin
  //TODO: We Should refactor this later
  useEffect(() => {
    if (!objectToQueryString(params)) {
      setState(initialState);
    }
  }, [params]);

  const handleOpenChange = newOpen => {
    setOpenCityPopover(newOpen);
  };

  const handleChangeCity = newCity => {
    setState(prevState => ({
      ...prevState,
      city: newCity,
    }));
    setOpenCityPopover(false);
  };

  const handleChangeSearchInput = event => {
    setState(prevState => ({
      ...prevState,
      search: event.target.value,
    }));
  };

  const cityContent = (
    <div className="city-container">
      {cities.map(item => (
        <City
          key={item.id}
          city={item}
          active={item.id === state.city?.id}
          selectCity={handleChangeCity}
        />
      ))}
    </div>
  );

  const movePin = useCallback((dragIndex, hoverIndex, isOver) => {
    const dragPin = pinsList[dragIndex];
    setPinsList(
      update(pinsList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragPin],
        ],
      }),
      hoverIndex,
      isOver
    )
  }, [pinsList])

  return (
    <div className="pins-list">
      <Collapse
        className="pins-list__collapse"
        expandIcon={panelProps => (
          hasAccess && <Icon
            type="arrow-down"
            width={12}
            className={clsx(
              "pins-list__collapse-icon",
              panelProps.isActive && "pins-list__collapse-icon--rotate"
            )}
          />
        )}
        expandIconPosition="end"
      >
        <Panel header={selectedPin?.name} key="1">
          <div className="pins-list__panel">
            { hasAccess && <Input
              size="small"
              className="pins-list__input"
              prefix={<Icon type="search-thin" width={12} />}
              onChange={handleChangeSearchInput}
              value={state.search}
              // TODO: needs to be excluded
              suffix={
                <Popover
                  placement="bottomRight"
                  showArrow={false}
                  content={cityContent}
                  trigger="click"
                  overlayClassName="select-city-popover"
                  open={openCityPopover}
                  onOpenChange={handleOpenChange}
                  title={
                    <div
                      className="select-city-popover__icon-container"
                      onClick={() => handleChangeCity(null)}
                    >
                      <p className="select-city-popover__title">
                        {$t("select.city")}
                      </p>
                      <Icon type="location" width={10} />
                    </div>
                  }
                >
                  <span className="pins-list__input--suffix">
                    <Icon type="location" width={10} />
                  </span>
                </Popover>
              }
              placeholder={$t(!state.city?.id ? "search" : "search.in.city", {
                city: state.city?.name,
              })}
            />
            }

            <div className="pins-list__list">
              <PinsList
                pins={pinsList}
                isFetched={isFetched}
                loading={loading}
                selectedPin={selectedPin}
                movePin={movePin}
                hasAccess={hasAccess}
              />
            </div>

            {hasAccess && <Link
              to={"/pin/create"}
              className="pins-list__add-pin"
              onClick={closeDrawer}
            >
              <Icon type="plus-circle" width={30} />
            </Link>
            }
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}
