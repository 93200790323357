import Client from "./client";

const baseUrl = `/city`;

export default {
  getCityList: ({ params = "" } = {}) => {
    const url = baseUrl + params;
    return Client.get(url);
  },
};
