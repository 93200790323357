import React, { useEffect, useState } from "react";
import { leftPanelMenuItems } from "common/constants/menu";
import { Link, NavLink } from "react-router-dom";
import $t from "translation";
import Logo from "assets/images/logo.png";
import userActions from "features/user/action";
import { useUserState } from "features/user/context/user-provider";
import { usePinContext } from "features/pin/context/pins-provider";
import NotFoundImage from "assets/images/not-found-image.svg";
import PinsListWrapper from "features/pin/components/list-wrapper";
import { Button, Drawer } from "antd";
import "./drawer.less";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import pinApi from "api/pin";

export default function ({ closeDrawer, open }) {
  const { selectedPin, searchedPins } = usePinContext();
  const [pinsList, setPinsList] = useState(searchedPins.sort((a, b) => a.position > b.position));

  const { state: { isSuperAdmin, isBartender, role }, dispatch } = useUserState();

  const leftMenu = leftPanelMenuItems.filter(item => item.roles.includes(role));

  useEffect(() => {
    setPinsList(searchedPins.sort((a, b) => a.position > b.position))
  }, [searchedPins])

  const updatePins = (pins, hoverIndex, isOver) => {
    setPinsList(pins);
    const previousPinId = pins[hoverIndex - 1]?.id ?? '';
    const pinId = pins[hoverIndex].id;
    const nextPinId = pins[hoverIndex + 1]?.id ?? '';

    // call api after mouseUp and dragging isOver
    isOver && pinApi.updatePinPosition({
      payload: {
        previousPinId,
        pinId,
        nextPinId
      },
    });
  };

  const handleLogout = () => {
    dispatch({ type: userActions.LOGOUT });
  };

  return (
    <Drawer
      placement="left"
      width={300}
      open={open}
      onClose={closeDrawer}
      className="main-layout__drawer"
      closable={false}
      getContainer={".main-layout"}
      push
    >
      <div className="drawer">
        <img
          src={selectedPin?.image?.fileUrl || NotFoundImage}
          className="drawer__avatar"
          alt="pin-logo" />
        <DndProvider backend={HTML5Backend} context={window}>
          <PinsListWrapper
            hasAccess={!isBartender}
            pinsList={pinsList}
            setPinsList={updatePins}
            closeDrawer={closeDrawer}
          />
        </DndProvider>
        <div className={"drawer__menu"}>
          {leftMenu.map(item => (
            <NavLink
              end
              to={item.url?.replace(":pinId", selectedPin?.id)}
              onClick={closeDrawer}
              key={item.key}
              className="drawer__menu-item"
            >
              {$t(item.key)}
            </NavLink>
          ))}
        </div>
        <div className="drawer__menu-item" onClick={handleLogout}>
          {$t("auth.logout")}
        </div>
        <Link
          to={`/pin/${selectedPin?.id}/analytics`}
          className="drawer__logo-container"
          onClick={closeDrawer}
        >
          <img src={Logo} alt="logo" className="drawer__logo" />
        </Link>
        {isSuperAdmin && (
          <Link
            to={`/admin/analytics/total`}
            onClick={closeDrawer}
          >
            <Button type={'primary'} className={'admin-link'}>
              {$t('admin')}
            </Button>
          </Link>
        )}
      </div>
    </Drawer>
  )
}
