import { createIntl, createIntlCache } from "react-intl";
import enMessage from "./locales/en.json";
const cache = createIntlCache();
const locale = "EN";
const intl = createIntl({ messages: enMessage, locale }, cache);


const $t = (str, values) => {
  if(!!str) {
    return intl.formatMessage(
      {
        id: str,
        defaultMessage: str,
      },
      values,
    );
  }
  return ""
};

export default $t;
