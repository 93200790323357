import PropTypes from "prop-types";
import clsx from "clsx";
import "./icon.less";

const Icon = ({ type, width, height, className, ...rest }) => {
  let src = "";

  try {
    src = require(`assets/icons/${type}.svg`);
  } catch (error) {
    console.error(`There is no icon with "${type}" type`);
  }

  return (
    <img
      className={clsx("icon", className)}
      alt={type}
      {...{ src, width, height }}
      {...rest}
    />
  );
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.any,
};

export default Icon;
